.landingpage {
  text-align: center;
  color: black;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;
}

.landingpage-section-color {
  background-image: url("../../../assets/backgroundgradient.png");
  background-size: cover;
  height: 600px;
  margin-bottom: 50px;
}

.landingpage-text {
  padding-top: 150px;
  padding-bottom: 50px;
  font-family: "SF UI Display Thin";
}

.landingpage-text .landingpage-heading {
  font-size: 50px;
  line-height: 50px;
  margin-bottom: 25px;
}

.landingpage-text .landingpage-section-header {
  font-size: 40px;
  line-height: 50px;
  margin-bottom: 25px;
}

.landingpage-section-text {
  font-size: 24px;
  margin-bottom: 25px;
  color: #001523;
}

.landingpage-get-started {
  border-radius: 30px;
  background-image: linear-gradient(to top, #5b2cc0, #9665be);
  font-family: "SF UI Display Light";
  width: 300px;
  height: 60px;
  color: white;
  font-size: 24px;
  transition: all 0.3s ease 0s;
  border-color: transparent;
}

.landingpage-get-started:hover {
  box-shadow: 0px 15px 20px rgba(150, 101, 190, 0.4);
  transform: translateY(-5px);
}

@media (min-width: 768px) {
  .landingpage-text {
    padding-bottom: 100px;
  }

  .landingpage-text .landingpage-heading {
    font-size: 75px;
    line-height: 75px;
    margin-bottom: 50px;
  }
}
