.container-color {
  padding-top: 100px;
  height: 100vh;
}

.project-checkbutton-container {
  border-radius: 30px;
  border: 1.8px solid #720ccb;
  box-sizing: border-box;
  background-color: white;
  color: black;
  /* font-family: 'SF UI Display Light'; */
  margin-top: 20px;
  width: 150px;
  padding: 0;
  height: 40px;
  z-index: 1000;
}

.project-checkbutton {
  width: 70%;
  margin-left: 35%;
  justify-content: center;
  align-items: center;
  /* position: absolute; */
  /* top: 40%; */
}

.project-checkbutton-partial {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  position: relative;
  width: 30%;
  height: 100%;
  float: left;
  background-color: #50e3c2;
}

.top-row-button {
  border-radius: 30px;
  border: 2px solid transparent;
  background-color: white;
  color: #720ccb;
  /* font-family: 'SF UI Display Medium'; */
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 20px;
}

.top-row-add-btn:hover {
  color: white;
}

.top-row-add-btn {
  border-radius: 30px;
  border: 2px solid transparent;
  color: white;
  background-image: linear-gradient(to top, #5b2cc0, #9665be);
  /* font-family: 'SF UI Display Medium'; */
  margin-left: 5px;
  margin-right: 5px;
}

.top-row-input {
  font-size: 0.8rem;
  min-width: 200px;
  max-width: 400px;
  border: none;
}
