@import "./fonts/style.css";
@import "./modules/common/landingpage/header-styling.css";
@import "./modules/common/landingpage/navigationbar-styling.css";
@import "./modules/translation/translation-styles.css";

html {
  height: 100%;
  overflow: scroll;
  overflow-x: hidden;
}

a:hover {
  text-decoration: none;
  color: black;
}

.file-upload {
  display: none;
}

::-webkit-scrollbar {
  width: 0px; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: #ff0000;
}

body {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 12px;
  color: #000000;
  height: 100%;
  -webkit-font-smoothing: antialiased;
}

button:focus {
  outline: none;
}

.btn:focus {
  outline: none !important;
  box-shadow: none;
}

button:hover {
  opacity: 0.9;
}

button:disabled {
  color: gray;
  background-color: white;
}

a {
  color: inherit;
}

.link-style {
  text-decoration-line: none;
}

.card-title-style {
  font-family: "SF UI Display Light";
  font-size: 1.5rem;
  -webkit-font-smoothing: antialiased;
}

.card-text-style {
  font-family: "SF UI Display Light";
  font-size: 0.8rem;
  -webkit-font-smoothing: antialiased;
}

.medium-text {
  font-family: "SF UI Display Light";
  font-size: 1.5rem;
  -webkit-font-smoothing: antialiased;
}

.small-text {
  font-family: "SF UI Display Light";
  font-weight: 400;
  font-size: 1rem;
  -webkit-font-smoothing: antialiased;
}

.general-button {
  border-radius: 30px;
  background-image: linear-gradient(to top, #5b2cc0, #9665be);
  width: 300px;
  height: 60px;
  color: white;
  font-size: 24px;
  border-color: transparent;
  font-family: "SF UI Display Medium";
}

/* .general-button:hover {
  color: #fcb103;
} */

.topbar-shadow {
  -webkit-box-shadow: 0 8px 6px -6px #999;
  -moz-box-shadow: 0 8px 6px -6px #999;
  box-shadow: 0 8px 6px -6px #999;
  /* the rest of your styling */
}

.form-control {
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.navbar-item {
  margin: 0;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.create-project-card {
  border: 3px dotted gainsboro !important;
  min-height: 200px;
  max-height: 250px;
  max-width: 300px;
}

.create-project-card.disabled {
  color: gray;
  opacity: 0.5;
  cursor: none;
  pointer-events: none;
}

.create-project-card.disabled button {
  color: white;
  background-color: gray !important;
  pointer-events: none;
}

.project-card {
  border-radius: 10px;
  min-height: 200px;
  max-height: 250px;
  max-width: 300px;
  cursor: pointer;
}

.form-control {
  color: #000000;
}

.modal-button {
  border-radius: 30px;
  background-image: linear-gradient(to top, #5b2cc0, #9665be);
  color: white;
  border-color: transparent;
  font-family: "SF UI Display Medium";
}

.ReactVirtualized__Grid__innerScrollContainer {
  width: auto;
  max-width: 1650px;
  overflow-y: visible !important;
  position: relative;
  min-height: 70vh !important;
  /* max-height: 70vh !important; */
}
