/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
font-family: 'SF UI Display Ultralight';
font-style: normal;
font-weight: normal;
src: local('SF UI Display Ultralight'), url(/static/media/sf-ui-display-ultralight-58646b19bf205.2297c033.woff) format('woff');
}


@font-face {
font-family: 'SF UI Display Thin';
font-style: normal;
font-weight: normal;
src: local('SF UI Display Thin'), url(/static/media/sf-ui-display-thin-58646e9b26e8b.f5fe5b03.woff) format('woff');
}


@font-face {
font-family: 'SF UI Display Light';
font-style: normal;
font-weight: normal;
src: local('SF UI Display Light'), url(/static/media/sf-ui-display-light-58646b33e0551.4b66431e.woff) format('woff');
}


@font-face {
font-family: 'SF UI Display Medium';
font-style: normal;
font-weight: normal;
src: local('SF UI Display Medium'), url(/static/media/sf-ui-display-medium-58646be638f96.3d7fe886.woff) format('woff');
}


@font-face {
font-family: 'SF UI Display Semibold';
font-style: normal;
font-weight: normal;
src: local('SF UI Display Semibold'), url(/static/media/sf-ui-display-semibold-58646eddcae92.da710864.woff) format('woff');
}


@font-face {
font-family: 'SF UI Display Bold';
font-style: normal;
font-weight: normal;
src: local('SF UI Display Bold'), url(/static/media/sf-ui-display-bold-58646a511e3d9.2c44b59c.woff) format('woff');
}


@font-face {
font-family: 'SF UI Display Heavy';
font-style: normal;
font-weight: normal;
src: local('SF UI Display Heavy'), url(/static/media/sf-ui-display-heavy-586470160b9e5.a06f68e8.woff) format('woff');
}


@font-face {
font-family: 'SF UI Display Black';
font-style: normal;
font-weight: normal;
src: local('SF UI Display Black'), url(/static/media/sf-ui-display-black-58646a6b80d5a.aebbf953.woff) format('woff');
}
.landingpage {
  text-align: center;
  color: black;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;
}

.landingpage-section-color {
  background-image: url(/static/media/backgroundgradient.56a9d7d5.png);
  background-size: cover;
  height: 600px;
  margin-bottom: 50px;
}

.landingpage-text {
  padding-top: 150px;
  padding-bottom: 50px;
  font-family: "SF UI Display Thin";
}

.landingpage-text .landingpage-heading {
  font-size: 50px;
  line-height: 50px;
  margin-bottom: 25px;
}

.landingpage-text .landingpage-section-header {
  font-size: 40px;
  line-height: 50px;
  margin-bottom: 25px;
}

.landingpage-section-text {
  font-size: 24px;
  margin-bottom: 25px;
  color: #001523;
}

.landingpage-get-started {
  border-radius: 30px;
  background-image: linear-gradient(to top, #5b2cc0, #9665be);
  font-family: "SF UI Display Light";
  width: 300px;
  height: 60px;
  color: white;
  font-size: 24px;
  transition: all 0.3s ease 0s;
  border-color: transparent;
}

.landingpage-get-started:hover {
  box-shadow: 0px 15px 20px rgba(150, 101, 190, 0.4);
  transform: translateY(-5px);
}

@media (min-width: 768px) {
  .landingpage-text {
    padding-bottom: 100px;
  }

  .landingpage-text .landingpage-heading {
    font-size: 75px;
    line-height: 75px;
    margin-bottom: 50px;
  }
}

.nav-link {
  color: black;
  font-family: 'SF UI Display Medium';
}

.nav-link:hover {
  color: blue;
  cursor: pointer;
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
}

@media (min-width: 992px) {
  /* .main-nav {
    padding-top: 25px;
    padding-bottom: 25px;
    -webkit-transition: padding-top 0.3s, padding-bottom 0.3s;
    transition: padding-top 0.3s, padding-bottom 0.3s;
    border: none;
    background-color: transparent;
  } */
}
.container-color {
  padding-top: 100px;
  height: 100vh;
}

.project-checkbutton-container {
  border-radius: 30px;
  border: 1.8px solid #720ccb;
  box-sizing: border-box;
  background-color: white;
  color: black;
  /* font-family: 'SF UI Display Light'; */
  margin-top: 20px;
  width: 150px;
  padding: 0;
  height: 40px;
  z-index: 1000;
}

.project-checkbutton {
  width: 70%;
  margin-left: 35%;
  justify-content: center;
  align-items: center;
  /* position: absolute; */
  /* top: 40%; */
}

.project-checkbutton-partial {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  position: relative;
  width: 30%;
  height: 100%;
  float: left;
  background-color: #50e3c2;
}

.top-row-button {
  border-radius: 30px;
  border: 2px solid transparent;
  background-color: white;
  color: #720ccb;
  /* font-family: 'SF UI Display Medium'; */
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 20px;
}

.top-row-add-btn:hover {
  color: white;
}

.top-row-add-btn {
  border-radius: 30px;
  border: 2px solid transparent;
  color: white;
  background-image: linear-gradient(to top, #5b2cc0, #9665be);
  /* font-family: 'SF UI Display Medium'; */
  margin-left: 5px;
  margin-right: 5px;
}

.top-row-input {
  font-size: 0.8rem;
  min-width: 200px;
  max-width: 400px;
  border: none;
}

html {
  height: 100%;
  overflow: scroll;
  overflow-x: hidden;
}

a:hover {
  text-decoration: none;
  color: black;
}

.file-upload {
  display: none;
}

::-webkit-scrollbar {
  width: 0px; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: #ff0000;
}

body {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 12px;
  color: #000000;
  height: 100%;
  -webkit-font-smoothing: antialiased;
}

button:focus {
  outline: none;
}

.btn:focus {
  outline: none !important;
  box-shadow: none;
}

button:hover {
  opacity: 0.9;
}

button:disabled {
  color: gray;
  background-color: white;
}

a {
  color: inherit;
}

.link-style {
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
}

.card-title-style {
  font-family: "SF UI Display Light";
  font-size: 1.5rem;
  -webkit-font-smoothing: antialiased;
}

.card-text-style {
  font-family: "SF UI Display Light";
  font-size: 0.8rem;
  -webkit-font-smoothing: antialiased;
}

.medium-text {
  font-family: "SF UI Display Light";
  font-size: 1.5rem;
  -webkit-font-smoothing: antialiased;
}

.small-text {
  font-family: "SF UI Display Light";
  font-weight: 400;
  font-size: 1rem;
  -webkit-font-smoothing: antialiased;
}

.general-button {
  border-radius: 30px;
  background-image: linear-gradient(to top, #5b2cc0, #9665be);
  width: 300px;
  height: 60px;
  color: white;
  font-size: 24px;
  border-color: transparent;
  font-family: "SF UI Display Medium";
}

/* .general-button:hover {
  color: #fcb103;
} */

.topbar-shadow {
  box-shadow: 0 8px 6px -6px #999;
  /* the rest of your styling */
}

.form-control {
  border-color: inherit;
  box-shadow: none;
}

.navbar-item {
  margin: 0;
  top: 50%;
  transform: translateY(-50%);
}

.create-project-card {
  border: 3px dotted gainsboro !important;
  min-height: 200px;
  max-height: 250px;
  max-width: 300px;
}

.create-project-card.disabled {
  color: gray;
  opacity: 0.5;
  cursor: none;
  pointer-events: none;
}

.create-project-card.disabled button {
  color: white;
  background-color: gray !important;
  pointer-events: none;
}

.project-card {
  border-radius: 10px;
  min-height: 200px;
  max-height: 250px;
  max-width: 300px;
  cursor: pointer;
}

.form-control {
  color: #000000;
}

.modal-button {
  border-radius: 30px;
  background-image: linear-gradient(to top, #5b2cc0, #9665be);
  color: white;
  border-color: transparent;
  font-family: "SF UI Display Medium";
}

.ReactVirtualized__Grid__innerScrollContainer {
  width: auto;
  max-width: 1650px;
  overflow-y: visible !important;
  position: relative;
  min-height: 70vh !important;
  /* max-height: 70vh !important; */
}

.input-field {
  border-top: none;
  border-right: 0;
  border-left: 0;
  font-size: 30px;
  outline-width: 0;
  outline: none;
  border-radius: 0;
}

.modal-content {
  max-height: 800px;
}

.modal-body {
  overflow-y: scroll;
}

