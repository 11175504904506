.nav-link {
  color: black;
  font-family: 'SF UI Display Medium';
}

.nav-link:hover {
  color: blue;
  cursor: pointer;
  text-decoration-line: none;
}

@media (min-width: 992px) {
  /* .main-nav {
    padding-top: 25px;
    padding-bottom: 25px;
    -webkit-transition: padding-top 0.3s, padding-bottom 0.3s;
    transition: padding-top 0.3s, padding-bottom 0.3s;
    border: none;
    background-color: transparent;
  } */
}